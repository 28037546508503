import React from 'react';
import { TreatmentsSubpage } from 'views/treatments-subpage';
import { useHyaluronicAcidContent } from 'views/treatments-subpage/hooks/acid';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { treatmentsHubs } from 'constants/treatment-hubs';
import { Layout } from 'layouts';

const HyaluronicAcidPage = () => {
  const hialuronicAcidContent = useHyaluronicAcidContent();

  return (
    <Layout>
      <TreatmentsSubpage
        breadcrumbLinks={treatmentsHubs.hialuronicAcid.links}
        {...hialuronicAcidContent}
      />
    </Layout>
  );
};

export default HyaluronicAcidPage;

export const Head = () => <SEO tags={TAGS.zabiegiKwasHialuronowy} />;
